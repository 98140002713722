<template>
  <div class="MyTeacherCourse">
    <table-list
      title="收支明细"
      :loading="loading"
      :search-form="searchForm"
      :data="myOrderList"
      :columns="columns(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @selectionChange="handleSelectionChange"
    ></table-list>
  </div>
</template>
<script>
// 搜索框配置
const searchForm = [
  {
    label: '流水编号',
    prop: 'serialCode',
  },
  {
    label: '订单编号',
    prop: 'orderId',
  },
  {
    label: '支付渠道',
    type: 'select',
    prop: 'payType',
    children: [
      { value: 'ALIPAY', label: '支付宝' },
      { value: 'WXPAY', label: '微信' },
      { value: 'BALANCE', label: '余额' },
    ],
  },
  {
    label: '收支类型',
    type: 'select',
    prop: 'reType',
    children: [
      { value: 1, label: '收入' },
      { value: 2, label: '支出' },
    ],
  },
  {
    label: '交易类型',
    type: 'select',
    prop: 'tradeType',
    children: [
      { value: 1, label: '商品售卖' },
      { value: 2, label: '退款' },
    ],
  },
  {
    label: '创建时间',
    type: 'picker',
    prop: 'createTime',
  },
  {
    label: '支付时间',
    type: 'picker',
    prop: 'payTime',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'serialCode',
    label: '流水编号',
    minWidth: 120,
  },
  {
    prop: 'orderId',
    label: '订单编号',
    minWidth: 120,
  },
  {
    label: '流水金额',
    minWidth: 100,
    render: (h, { row }) => {
      return h('div', row.amount ? '￥' + row.amount : '')
    },
  },
  {
    prop: 'payType',
    label: '支付渠道',
    formatter: row => {
      return { ALIPAY: '支付宝', WXPAY: '微信', BALANCE: '余额' }[row.payType]
    },
    minWidth: 100,
  },
  {
    prop: 'tradeType',
    label: '交易类型',
    formatter: row => {
      return { 1: '商品售卖', 2: '退款' }[row.tradeType]
    },
    minWidth: 100,
  },
  {
    prop: 'reType',
    label: '收支类型',
    formatter: row => {
      return { 1: '收入', 2: '支出' }[row.reType]
    },
    minWidth: 100,
  },
  {
    prop: 'createTime',
    label: '创建时间',
    minWidth: 120,
  },
  {
    prop: 'payTime',
    label: '支付时间',
    minWidth: 120,
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleDc,
    type: 'danger',
  },
]
import TableList from '@/components/TableList'
import {
  merchantTeacherIncomeAndPayFlow,
  merchantTeacherIncomeAndPayFlowExport,
} from '@/api/teacher'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      loading: false,
      columns,
      btns,
      searchForm,
      queryParams: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
        createEndTime: '',
        createStartTime: '',
        orderId: '',
        payEndTime: '',
        payStartTime: '',
        payType: '',
        reType: null,
        serialCode: '',
        tradeType: null,
      },
      myOrderList: [],
      selectOrders: [],
    }
  },
  mounted() {
    this.getOrderList()
  },
  methods: {
    async getOrderList() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(merchantTeacherIncomeAndPayFlow(this.pager))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.myOrderList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.pager.serialCode = queryParams.serialCode
      this.pager.orderId = queryParams.orderId
      this.pager.payType = queryParams.payType
      this.pager.reType = queryParams.reType
      this.pager.tradeType = queryParams.tradeType
      if (queryParams.createTime) {
        this.pager.createStartTime = queryParams.createTime[0]
        this.pager.createEndTime = queryParams.createTime[1]
      } else {
        this.pager.createStartTime = ''
        this.pager.createEndTime = ''
      }
      if (queryParams.payTime) {
        this.pager.payStartTime = queryParams.payTime[0]
        this.pager.payEndTime = queryParams.payTime[1]
      } else {
        this.pager.payStartTime = ''
        this.pager.payEndTime = ''
      }
      this.getOrderList()
    },
    async handleDc() {
      let ids = []
      this.selectOrders.map(item => {
        ids.push(item.serialCode)
      })
      const [res, err] = await to(
        merchantTeacherIncomeAndPayFlowExport({ serialCodeList: ids, ...this.pager }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '我的订单.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getOrderList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getOrderList()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectOrders = val
    },
  },
}
</script>
